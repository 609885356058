import * as namedRoutesForExport from './routes';

export const namedRoutes: {
	[name: string]: { name: string; pattern: string };
} = namedRoutesForExport;

export const EMBEDDED_PARENT_PRODUCTS = {
	JSM_PORTAL: 'JSM-Portal',
	JSM: 'JSM',
};

export { Footer } from './Footer';
export type { PageCommonProps } from './PageCommonProps';
export { EMBEDDED_CONFLUENCE_MODE } from './EmbeddedConfluenceMode';
export { EMBEDDED_CONFLUENCE_CONTENT_TYPE } from './EmbeddedConfluenceContentType';
export { TemplateType } from './Template';
export { useFooter } from './useFooter';
export { useUrl } from './useUrl';
export { useContentSize } from './useContentSize';
export { PageLoadValidation } from './PageLoadValidation';
