import { exposeToMeetWhenScreensharing } from './exposeMeet';

// Exported for testing
export const SCRIPT_SRC = 'https://www.gstatic.com/meetjs/addons/0.7.0/meet.addons.screenshare.js';

export const appendGoogleMeetScreenSharingScript = (href: string): void => {
	// Create script
	const script = document.createElement('script');
	script.src = SCRIPT_SRC;
	script.async = true;
	// When script is done loading, run the Google Meet screenshare method
	script.onload = () => {
		exposeToMeetWhenScreensharing(href);
	};
	// Add script to DOM
	document.head.appendChild(script);
};
