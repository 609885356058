import React from 'react';

import { usePageContentId } from '@confluence/page-context';

import { useSessionData } from '@confluence/session-data';

import { LegacyBridgeNextInner } from './LegacyBridgeNextInner';

export function LegacyBridgeNext() {
	const [contentId] = usePageContentId();
	const { cloudId } = useSessionData();

	return !contentId || !cloudId ? null : (
		<LegacyBridgeNextInner cloudId={cloudId} contentId={contentId} />
	);
}
